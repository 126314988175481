import './App.css';
import { Typography } from '@mui/material'
import AuctionBrowser from './components/AuctionBrowser'

function App() {
  return (
    <div className="App" style={{ maxWidth: 1600, margin: '16px auto' }}>
      <a href="https://www.tek-edge-gaming.com">
        <img style={{ maxWidth: '100%' }}src="TekEdgeBanner.png"></img>
      </a>
      <br></br>
      <Typography sx={{ color: '#9b9b9b', fontStyle: 'italic' }} variant="subtitle2">Note: Auction data is cached and only updated once an hour</Typography>
      <AuctionBrowser />
    </div>
  );
}

export default App;
