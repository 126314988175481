export default {
    "/Game/PrimalEarth/Dinos/Achatina/Achatina_Character_BP.Achatina_Character_BP": "Achatina",
    "/Game/PrimalEarth/Dinos/Achatina/Achatina_Character_BP_Aberrant.Achatina_Character_BP_Aberrant": "Achatina",
    "/Game/PrimalEarth/Dinos/Allosaurus/Allo_Character_BP.Allo_Character_BP": "Allosaurus",
    "/Game/Genesis/Dinos/BiomeVariants/Volcano_Allosaurus/Volcano_Allo_Character_BP.Volcano_Allo_Character_BP": "Allosaurus",
    "/Game/Genesis2/Dinos/BiomeVariants/Allo_Character_BP_Rockwell.Allo_Character_BP_Rockwell": "Allosaurus",
    "/Game/Genesis2/Missions/ModularMission/Gauntlet2/STA/Endless/Tames/Allo_Character_BP_TameSTA.Allo_Character_BP_TameSTA": "Allosaurus",
    "/Game/LostIsland/Dinos/Amargasaurus/Amargasaurus_Character_BP.Amargasaurus_Character_BP": "Amargasaurus",
    "/Game/PrimalEarth/Dinos/Ammonite/Ammonite_Character.Ammonite_Character": "Ammonite",
    "/Game/PrimalEarth/Dinos/Anglerfish/Angler_Character_BP.Angler_Character_BP": "Anglerfish",
    "/Game/PrimalEarth/Dinos/Anglerfish/Angler_Character_BP_Aberrant.Angler_Character_BP_Aberrant": "Anglerfish",
    "/Game/PrimalEarth/Dinos/Ankylo/Ankylo_Character_BP.Ankylo_Character_BP": "Ankylosaurus",
    "/Game/PrimalEarth/Dinos/Ankylo/Ankylo_Character_BP_Aberrant.Ankylo_Character_BP_Aberrant": "Ankylosaurus",
    "/Game/Genesis/Dinos/BiomeVariants/Volcano_Ankylosaurus/Volcano_Ankylo_Character_BP.Volcano_Ankylo_Character_BP": "Ankylosaurus",
    "/Game/PrimalEarth/Dinos/Spider-Small/SpiderS_Character_BP.SpiderS_Character_BP": "Araneo",
    "/Game/PrimalEarth/Dinos/Spider-Small/SpiderS_Character_BP_Aberrant.SpiderS_Character_BP_Aberrant": "Araneo",
    "/Game/PrimalEarth/Dinos/Archaeopteryx/Archa_Character_BP.Archa_Character_BP": "Archaeopteryx",
    "/Game/PrimalEarth/Dinos/Argentavis/Argent_Character_BP.Argent_Character_BP": "Argentavis",
    "/Game/Genesis/Dinos/BiomeVariants/Snow_Argentavis/Snow_Argent_Character_BP.Snow_Argent_Character_BP": "Argentavis",
    "/Game/PrimalEarth/Dinos/Arthropluera/Arthro_Character_BP.Arthro_Character_BP": "Arthropluera",
    "/Game/PrimalEarth/Dinos/Arthropluera/Arthro_Character_BP_Aberrant.Arthro_Character_BP_Aberrant": "Arthropluera",
    "/Game/Extinction/Dinos/Arthropluera/Arthro_Character_BP_Corrupt.Arthro_Character_BP_Corrupt": "Arthropluera",
    "/Game/Genesis/Dinos/SpaceWhale/SpaceWhale_Character_BP.SpaceWhale_Character_BP": "Astrocetus",
    "/Game/Genesis2/Dinos/SpaceDolphin/SpaceDolphin_Character_BP.SpaceDolphin_Character_BP": "Astrodelphis",
    "/Game/EndGame/Dinos/Drone/EndDrone_Character_BP.EndDrone_Character_BP": "Attack Drone",
    "/Game/Genesis/Dinos/BiomeVariants/VR/VREndDrone_Character_BP.VREndDrone_Character_BP": "Attack Drone",
    "/Game/Genesis2/Missions/ModularMission/LifeSupport/Dinos/EndDrone_Character_BP_Malfunctioned_LifeSupport.EndDrone_Character_BP_Malfunctioned_LifeSupport": "Attack Drone",
    "/Game/PrimalEarth/Dinos/Baryonyx/Baryonyx_Character_BP.Baryonyx_Character_BP": "Baryonyx",
    "/Game/PrimalEarth/Dinos/Baryonyx/Baryonyx_Character_BP_Aberrant.Baryonyx_Character_BP_Aberrant": "Baryonyx",
    "/Game/Aberration/Dinos/Basilisk/Basilisk_Character_BP.Basilisk_Character_BP": "Basilisk",
    "/Game/PrimalEarth/Dinos/Basilosaurus/Basilosaurus_Character_BP.Basilosaurus_Character_BP": "Basilosaurus",
    "/Game/Genesis/Dinos/BiomeVariants/Ocean_Basilosaurus/Ocean_Basilosaurus_Character_BP.Ocean_Basilosaurus_Character_BP": "Basilosaurus",
    "/Game/PrimalEarth/Dinos/Toad/Toad_Character_BP.Toad_Character_BP": "Beelzebufo",
    "/Game/PrimalEarth/Dinos/Toad/Toad_Character_BP_Aberrant.Toad_Character_BP_Aberrant": "Beelzebufo",
    "/Game/Genesis/Dinos/BogSpider/BogSpider_Character_BP.BogSpider_Character_BP": "Bloodstalker",
    "/Game/PrimalEarth/Dinos/Sauropod/Sauropod_Character_BP.Sauropod_Character_BP": "Brontosaurus",
    "/Game/Genesis2/Dinos/BiomeVariants/Sauropod_Character_BP_Rockwell.Sauropod_Character_BP_Rockwell": "Brontosaurus",
    "/Game/PrimalEarth/Dinos/Spider-Large/SpiderL_Character_BP.SpiderL_Character_BP": "Broodmother Lysrix",
    "/Game/Aberration/Dinos/LanternPug/LanternPug_Character_BP.LanternPug_Character_BP": "Bulbdog",
    "/Game/PrimalEarth/Dinos/Turtle/Turtle_Character_BP.Turtle_Character_BP": "Carbonemys",
    "/Game/PrimalEarth/Dinos/Turtle/Turtle_Character_BP_Aberrant.Turtle_Character_BP_Aberrant": "Carbonemys",
    "/Game/Genesis2/Dinos/BiomeVariants/Turtle_Character_BP_Rockwell.Turtle_Character_BP_Rockwell": "Carbonemys",
    "/Game/PrimalEarth/Dinos/Carno/Carno_Character_BP.Carno_Character_BP": "Carnotaurus",
    "/Game/PrimalEarth/Dinos/Carno/Carno_Character_BP_Aberrant.Carno_Character_BP_Aberrant": "Carnotaurus",
    "/Game/PrimalEarth/Dinos/Carno/Carno_Character_BP_Corrupt.Carno_Character_BP_Corrupt": "Carnotaurus",
    "/Game/Genesis2/Dinos/BiomeVariants/Carno_Character_BP_Rockwell.Carno_Character_BP_Rockwell": "Carnotaurus",
    "/Game/PrimalEarth/Dinos/Beaver/Beaver_Character_BP.Beaver_Character_BP": "Castoroides",
    "/Game/PrimalEarth/Dinos/Chalicotherium/Chalico_Character_BP.Chalico_Character_BP": "Chalicotherium",
    "/Game/Extinction/Dinos/Corrupt/Chalicotherium/Chalico_Character_BP_Corrupt.Chalico_Character_BP_Corrupt": "Chalicotherium",
    "/Game/PrimalEarth/Dinos/Cnidaria/Cnidaria_Character_BP.Cnidaria_Character_BP": "Cnidaria",
    "/Game/PrimalEarth/Dinos/Cnidaria/Cnidaria_Character_BP_Aberrant.Cnidaria_Character_BP_Aberrant": "Cnidaria",
    "/Game/PrimalEarth/Dinos/Coelacanth/Coel_Character_BP.Coel_Character_BP": "Coelacanth",
    "/Game/PrimalEarth/Dinos/Coelacanth/Coel_Character_BP_Aberrant.Coel_Character_BP_Aberrant": "Coelacanth",
    "/Game/PrimalEarth/Dinos/Compy/Compy_Character_BP.Compy_Character_BP": "Compy",
    "/Game/Genesis/Dinos/Bots/Bot_Character_BP.Bot_Character_BP": "Corrupted Avatar",
    "/Game/Genesis/Dinos/VRMainBoss/VRMainBoss_Character.VRMainBoss_Character": "Corrupted Master Controller",
    "/Game/Genesis/Dinos/VRMainBoss/VRMainBoss_Character_Easy.VRMainBoss_Character_Easy": "Corrupted Master Controller",
    "/Game/Genesis/Dinos/VRMainBoss/VRMainBoss_Character_Medium.VRMainBoss_Character_Medium": "Corrupted Master Controller",
    "/Game/Genesis/Dinos/VRMainBoss/VRMainBoss_Character_Hard.VRMainBoss_Character_Hard": "Corrupted Master Controller",
    "/Game/Genesis2/Dinos/CorruptHumans/CorruptHuman_Character_BP_BASE.CorruptHuman_Character_BP_BASE": "Corrupted Survivor",
    "/Game/PrimalEarth/Dinos/CrystalWyvern/CrystalWyvern_Character_BP_WS.CrystalWyvern_Character_BP_WS": "Crystal Wyvern",
    "/Game/PrimalEarth/Dinos/CrystalWyvern/CrystalWyvern_Character_BP_Ember.CrystalWyvern_Character_BP_Ember": "Crystal Wyvern",
    "/Game/PrimalEarth/Dinos/CrystalWyvern/CrystalWyvern_Character_BP_Blood.CrystalWyvern_Character_BP_Blood": "Crystal Wyvern",
    "/Game/Mods/CrystalIsles/Assets/Dinos/CIBoss/Minions/CrystalWyvern_Character_BP_Minion_WS.CrystalWyvern_Character_BP_Minion_WS": "Crystal Wyvern",
    "/Game/Mods/CrystalIsles/Assets/Dinos/CIBoss/CrystalWyvern_Character_BP_Boss.CrystalWyvern_Character_BP_Boss": "Crystal Wyvern Queen",
    "/Game/PrimalEarth/Dinos/Daeodon/Daeodon_Character_BP.Daeodon_Character_BP": "Daeodon",
    "/Game/Genesis2/Dinos/BiomeVariants/Daeodon_Character_BP_Eden.Daeodon_Character_BP_Eden": "Daeodon",
    "/Game/ScorchedEarth/Dinos/Deathworm/Deathworm_Character_BP.Deathworm_Character_BP": "Deathworm",
    "/Game/EndGame/Dinos/Tank/EndTank_Character_BP.EndTank_Character_BP": "Defense Unit",
    "/Game/Genesis2/Missions/ModularMission/LifeSupport/Dinos/EndTank_Character_BP_LifeSupport.EndTank_Character_BP_LifeSupport": "Defense Unit",
    "/Game/PrimalEarth/Dinos/Raptor/Uberraptor/Deinonychus_Character_BP.Deinonychus_Character_BP": "Deinonychus",
    "/Game/Extinction/Dinos/DesertKaiju/DesertKaiju_Character_BP.DesertKaiju_Character_BP": "Desert Titan Flock",
    "/Game/Extinction/Dinos/DesertKaiju/DesertKaiju_FirstFlockChar_BP.DesertKaiju_FirstFlockChar_BP": "Desert Titan Flock",
    "/Game/PrimalEarth/Dinos/Dilo/Dilo_Character_BP.Dilo_Character_BP": "Dilophosaur",
    "/Game/Extinction/Dinos/Corrupt/Dilo/Dilo_Character_BP_Corrupt.Dilo_Character_BP_Corrupt": "Dilophosaur",
    "/Game/Genesis2/Dinos/BiomeVariants/Dilo_Character_BP_Rockwell.Dilo_Character_BP_Rockwell": "Dilophosaur",
    "/Game/Genesis2/Missions/ModularMission/Maze/CorruptedZone/Dinos/Dilo_Character_BP_CorrMaze.Dilo_Character_BP_CorrMaze": "Dilophosaur",
    "/Game/PrimalEarth/Dinos/Dimetrodon/Dimetro_Character_BP.Dimetro_Character_BP": "Dimetrodon",
    "/Game/PrimalEarth/Dinos/Dimetrodon/Dimetro_Character_BP_Aberrant.Dimetro_Character_BP_Aberrant": "Dimetrodon",
    "/Game/PrimalEarth/Dinos/Dimorphodon/Dimorph_Character_BP.Dimorph_Character_BP": "Dimorphodon",
    "/Game/PrimalEarth/Dinos/Dimorphodon/Dimorph_Character_BP_Aberrant.Dimorph_Character_BP_Aberrant": "Dimorphodon",
    "/Game/Extinction/Dinos/Corrupt/Dimorphodon/Dimorph_Character_BP_Corrupt.Dimorph_Character_BP_Corrupt": "Dimorphodon",
    "/Game/LostIsland/Dinos/Dinopithecus/Dinopithecus_Character_BP.Dinopithecus_Character_BP": "Dinopithecus",
    "/Game/LostIsland/Boss/BossDinopithecus_Character_BP_Easy.BossDinopithecus_Character_BP_Easy": "Dinopithecus King",
    "/Game/PrimalEarth/Dinos/Diplocaulus/Diplocaulus_Character_BP.Diplocaulus_Character_BP": "Diplocaulus",
    "/Game/PrimalEarth/Dinos/Diplocaulus/Diplocaulus_Character_BP_Aberrant.Diplocaulus_Character_BP_Aberrant": "Diplocaulus",
    "/Game/PrimalEarth/Dinos/Diplodocus/Diplodocus_Character_BP.Diplodocus_Character_BP": "Diplodocus",
    "/Game/PrimalEarth/Dinos/Diplodocus/Diplodocus_Character_BP_Aberrant.Diplodocus_Character_BP_Aberrant": "Diplodocus",
    "/Game/PrimalEarth/Dinos/Direbear/Direbear_Character_BP.Direbear_Character_BP": "Dire Bear",
    "/Game/PrimalEarth/Dinos/Direbear/Direbear_Character_Polar.Direbear_Character_Polar": "Dire Bear",
    "/Game/Mods/Ragnarok/Custom_Assets/Dinos/Polar_Bear/Polar_Bear.Polar_Bear": "Dire Bear",
    "/Game/Mods/Ragnarok/Custom_Assets/Bosses/LifeLabyrinth/Bear/Direbear_LL_Character_BP.Direbear_LL_Character_BP": "Dire Bear",
    "/Game/PrimalEarth/Dinos/Direbear/Direbear_Character_BP_Aberrant.Direbear_Character_BP_Aberrant": "Dire Bear",
    "/Game/Genesis2/Dinos/BiomeVariants/Direbear_Character_BP_Rockwell.Direbear_Character_BP_Rockwell": "Dire Bear",
    "/Game/PrimalEarth/Dinos/Direwolf/Direwolf_Character_BP.Direwolf_Character_BP": "Direwolf",
    "/Game/Mods/Ragnarok/Custom_Assets/Bosses/LifeLabyrinth/Wolf/Direwolf_LL_Character_BP.Direwolf_LL_Character_BP": "Direwolf",
    "/Game/Genesis2/Dinos/BiomeVariants/Direwolf_Character_BP_Eden.Direwolf_Character_BP_Eden": "Direwolf",
    "/Game/PrimalEarth/Dinos/Dodo/Dodo_Character_BP.Dodo_Character_BP": "Dodo",
    "/Game/PrimalEarth/Dinos/Dodo/Dodo_Character_BP_Aberrant.Dodo_Character_BP_Aberrant": "Dodo",
    "/Game/PrimalEarth/Dinos/Doedicurus/Doed_Character_BP.Doed_Character_BP": "Doedicurus",
    "/Game/PrimalEarth/Dinos/Doedicurus/Doed_Character_BP_Aberrant.Doed_Character_BP_Aberrant": "Doedicurus",
    "/Game/PrimalEarth/Dinos/Dragon/Dragon_Character_BP_Boss.Dragon_Character_BP_Bos": "Dragon",
    "/Game/PrimalEarth/Dinos/DungBeetle/DungBeetle_Character_BP.DungBeetle_Character_BP": "Dung Beetle",
    "/Game/PrimalEarth/Dinos/DungBeetle/DungBeetle_Character_BP_Aberrant.DungBeetle_Character_BP_Aberrant": "Dung Beetle",
    "/Game/PrimalEarth/Dinos/Dunkleosteus/Dunkle_Character_BP.Dunkle_Character_BP": "Dunkleosteus",
    "/Game/Genesis/Dinos/BiomeVariants/Ocean_Dunkleosteus/Ocean_Dunkle_Character_BP.Ocean_Dunkle_Character_BP": "Dunkleosteus",
    "/Game/Genesis/Dinos/EelBoss/EelBoss_Character_BP.EelBoss_Character_BP": "Moeder, Master of the Ocean",
    "/Game/Genesis/Dinos/EelBoss/EelBoss_Character_BP_Easy.EelBoss_Character_BP_Easy": "Moeder, Master of the Ocean",
    "/Game/Genesis/Dinos/EelBoss/EelBoss_Character_BP_Medium.EelBoss_Character_BP_Medium": "Moeder, Master of the Ocean",
    "/Game/Genesis/Dinos/EelBoss/EelBoss_Character_BP_Hard.EelBoss_Character_BP_Hard": "Moeder, Master of the Ocean",
    "/Game/PrimalEarth/Dinos/Eel/Eel_Character_BP.Eel_Character_BP": "Electrophorus",
    "/Game/PrimalEarth/Dinos/Eel/Eel_Character_BP_Aberrant.Eel_Character_BP_Aberrant": "Electrophorus",
    "/Game/Extinction/Dinos/Enforcer/Enforcer_Character_BP.Enforcer_Character_BP": "Enforcer",
    "/Game/Genesis2/Missions/ModularMission/LifeSupport/Dinos/Enforcer_Character_BP_Malfunctioned_LifeSupport.Enforcer_Character_BP_Malfunctioned_LifeSupport": "Enforcer",
    "/Game/Genesis2/Missions/ModularMission/Gauntlet2/STA/Endless/Tames/Enforcer_Character_BP_TameSTA.Enforcer_Character_BP_TameSTA": "Enforcer",
    "/Game/PrimalEarth/Dinos/Equus/Equus_Character_BP.Equus_Character_BP": "Equus",
    "/Game/PrimalEarth/Dinos/Equus/Equus_Character_BP_Aberrant.Equus_Character_BP_Aberrant": "Equus",
    "/Game/Genesis2/Dinos/BiomeVariants/Equus_Character_BP_Eden.Equus_Character_BP_Eden": "Equus",
    "/Game/PrimalEarth/Dinos/Eurypterid/Euryp_Character.Euryp_Character": "Eurypterid",
    "/Game/Genesis2/Dinos/Exosuit/Exosuit_Character_BP.Exosuit_Character_BP": "Mek",
    "/Game/Extinction/Dinos/Mek/MegaMek_Character_BP.MegaMek_Character_BP": "Mek",
    "/Game/Extinction/Dinos/Mek/Mek_Character_BP.Mek_Character_BP": "Mek",
    "/Game/Genesis2/Missions/ModularMission/LifeSupport/Dinos/Mek_Character_BP_LifeSupport.Mek_Character_BP_LifeSupport": "Mek",
    "/Game/Aberration/Dinos/LanternBird/LanternBird_Character_BP.LanternBird_Character_BP": "Featherlight",
    "/Game/Genesis/Dinos/Shapeshifter/Shapeshifter_Small/Shapeshifter_Small_Character_BP.Shapeshifter_Small_Character_BP": "Ferox",
    "/Game/Extinction/Dinos/ForestKaiju/ForestKaiju_Character_BP.ForestKaiju_Character_BP": "Forest Titan",
    "/Game/Extinction/Dinos/Gacha/Gacha_Character_BP.Gacha_Character_BP": "Gacha",
    "/Game/PrimalEarth/Dinos/Gallimimus/Galli_Character_BP.Galli_Character_BP": "Gallimimus",
    "/Game/Extinction/Dinos/GasBag/GasBags_Character_BP.GasBags_Character_BP": "Gasbags",
    "/Game/Genesis2/Dinos/BiomeVariants/GasBags_Character_BP_Eden.GasBags_Character_BP_Eden": "Gasbags",
    "/Game/PrimalEarth/Dinos/Bee/Bee_Character_BP.Bee_Character_BP": "Giant Queen Bee",
    "/Game/PrimalEarth/Dinos/Bee/Bee_Queen_Character_BP.Bee_Queen_Character_BP": "Giant Queen Bee",
    "/Game/Mods/CrystalIsles/Assets/Dinos/HoneyBee/HoneyBee_Character_BP.HoneyBee_Character_BP": "Giant Queen Bee",
    "/Game/PrimalEarth/Dinos/Giganotosaurus/Gigant_Character_BP.Gigant_Character_BP": "Giganotosaurus",
    "/Game/PrimalEarth/Dinos/Giganotosaurus/BionicGigant_Character_BP.BionicGigant_Character_BP": "Giganotosaurus",
    "/Game/PrimalEarth/Dinos/Giganotosaurus/BionicGigant_Character_BP_Malfunctioned.BionicGigant_Character_BP_Malfunctioned": "Giganotosaurus",
    "/Game/Genesis/Dinos/BiomeVariants/VR/VRGigant_Character_BP.VRGigant_Character_BP": "Giganotosaurus",
    "/Game/Extinction/Dinos/Corrupt/Giganotosaurus/Gigant_Character_BP_Corrupt.Gigant_Character_BP_Corrupt": "Giganotosaurus",
    "/Game/Genesis2/Dinos/BiomeVariants/Gigant_Character_BP_Rockwell.Gigant_Character_BP_Rockwell": "Giganotosaurus",
    "/Game/Genesis2/Missions/ModularMission/Mashup/ExperimentG/DinosAndStructures/Gigant_Character_BP_ExpG.Gigant_Character_BP_ExpG": "Giganotosaurus",
    "/Game/PrimalEarth/Dinos/Bigfoot/Bigfoot_Character_BP.Bigfoot_Character_BP": "Gigantopithecus",
    "/Game/PrimalEarth/Dinos/Bigfoot/Bigfoot_Character_BP_Aberrant.Bigfoot_Character_BP_Aberrant": "Gigantopithecus",
    "/Game/Aberration/Dinos/Lightbug/Lightbug_Character_BaseBP.Lightbug_Character_BaseBP": "Glowbug",
    "/Game/Aberration/Dinos/LanternLizard/LanternLizard_Character_BP.LanternLizard_Character_BP": "Glowtail",
    "/Game/PrimalEarth/Dinos/Griffin/Griffin_Character_BP.Griffin_Character_BP": "Griffin",
    "/Game/PrimalEarth/Dinos/Hesperornis/Hesperornis_Character_BP.Hesperornis_Character_BP": "Hesperornis",
    "/Game/PrimalEarth/CoreBlueprints/PlayerPawnTest_Male.PlayerPawnTest_Male": "Human",
    "/Game/PrimalEarth/CoreBlueprints/PlayerPawnTest_Female.PlayerPawnTest_Female": "Human",
    "/Game/PrimalEarth/Dinos/Hyaenodon/Hyaenodon_Character_BP.Hyaenodon_Character_BP": "Hyaenodon",
    "/Game/ScorchedEarth/Dinos/RockGolem/RockGolem_Character_BP.RockGolem_Character_BP": "Rock Elemental",
    "/Game/ScorchedEarth/Dinos/RockGolem/RubbleGolem_Character_BP.RubbleGolem_Character_BP": "Rock Elemental",
    "/Game/Mods/Valguero/Assets/Dinos/RockGolem/ChalkGolem/ChalkGolem_Character_BP.ChalkGolem_Character_BP": "Rock Elemental",
    "/Game/PrimalEarth/Dinos/IceGolem/IceGolem_Character_BP.IceGolem_Character_BP": "Rock Elemental",
    "/Game/Genesis/Dinos/BiomeVariants/Lava_Golem/Volcano_Golem_Character_BP.Volcano_Golem_Character_BP": "Rock Elemental",
    "/Game/Extinction/Dinos/IceKaiju/IceKaiju_Character_BP.IceKaiju_Character_BP": "Ice Titan",
    "/Game/ScorchedEarth/Dinos/Wyvern/Wyvern_Character_BP_Fire.Wyvern_Character_BP_Fire": "Wyvern",
    "/Game/ScorchedEarth/Dinos/Wyvern/Wyvern_Character_BP_Lightning.Wyvern_Character_BP_Lightning": "Wyvern",
    "/Game/ScorchedEarth/Dinos/Wyvern/Wyvern_Character_BP_Poison.Wyvern_Character_BP_Poison": "Wyvern",
    "/Game/Mods/Ragnarok/Custom_Assets/Dinos/Wyvern/Ice_Wyvern/Ragnarok_Wyvern_Override_Ice.Ragnarok_Wyvern_Override_Ice": "Wyvern",
    "/Game/Extinction/Dinos/ForestKaiju/Minion/Wyvern_Character_BP_Fire_Minion.Wyvern_Character_BP_Fire_Minion": "Wyvern",
    "/Game/Extinction/Dinos/Corrupt/Wyvern/Wyvern_Character_BP_Fire_Corrupt.Wyvern_Character_BP_Fire_Corrupt": "Wyvern",
    "/Game/Genesis/Dinos/MissionVariants/Gauntlet/Volcanic/Wyvern_Character_BP_Fire_Gauntlet.Wyvern_Character_BP_Fire_Gauntlet": "Wyvern",
    "/Game/PrimalEarth/Dinos/Ichthyornis/Ichthyornis_Character_BP.Ichthyornis_Character_BP": "Ichthyornis",
    "/Game/PrimalEarth/Dinos/Dolphin/Dolphin_Character_BP.Dolphin_Character_BP": "Ichthyosaurus",
    "/Game/Genesis/Dinos/BiomeVariants/Ocean_Dolphin/Ocean_Dolphin_Character_BP.Ocean_Dolphin_Character_BP": "Ichthyosaurus",
    "/Game/PrimalEarth/Dinos/Iguanodon/Iguanodon_Character_BP.Iguanodon_Character_BP": "Iguanodon",
    "/Game/PrimalEarth/Dinos/Iguanodon/Iguanodon_Character_BP_Aberrant.Iguanodon_Character_BP_Aberrant": "Iguanodon",
    "/Game/Genesis/Dinos/Swarms/InsectSwarmChar_BP.InsectSwarmChar_BP": "Insect Swarm",
    "/Game/ScorchedEarth/Dinos/Jerboa/Jerboa_Character_BP.Jerboa_Character_BP": "Jerboa",
    "/Game/ScorchedEarth/Dinos/JugBug/Jugbug_Character_BaseBP.Jugbug_Character_BaseBP": "Jug Bug",
    "/Game/ScorchedEarth/Dinos/JugBug/Jugbug_Oil_Character_BP.Jugbug_Oil_Character_BP": "Jug Bug",
    "/Game/ScorchedEarth/Dinos/JugBug/Jugbug_Water_Character_BP.Jugbug_Water_Character_BP": "Jug Bug",
    "/Game/PrimalEarth/Dinos/Kairuku/Kairuku_Character_BP.Kairuku_Character_BP": "Kairuku",
    "/Game/PrimalEarth/Dinos/Kaprosuchus/Kaprosuchus_Character_BP.Kaprosuchus_Character_BP": "Kaprosuchus",
    "/Game/Aberration/Dinos/Crab/Crab_Character_BP.Crab_Character_BP": "Karkinos",
    "/Game/PrimalEarth/Dinos/Kentrosaurus/Kentro_Character_BP.Kentro_Character_BP": "Kentrosaurus",
    "/Game/Extinction/Dinos/KingKaiju/KingKaiju_Character_BP.KingKaiju_Character_BP": "King Titan",
    "/Game/Extinction/Dinos/KingKaiju/KingKaiju_Character_BP_Beta.KingKaiju_Character_BP_Beta": "King Titan",
    "/Game/Extinction/Dinos/KingKaiju/KingKaiju_Character_BP_Alpha.KingKaiju_Character_BP_Alpha": "King Titan",
    "/Game/Extinction/Dinos/KingKaiju/CorruptTumor_Character_BP.CorruptTumor_Character_BP": "King Titan",
    "/Game/Aberration/Dinos/Lamprey/Lamprey_Character.Lamprey_Character": "Lamprey",
    "/Game/PrimalEarth/Dinos/Leech/Leech_Character.Leech_Character": "Leech",
    "/Game/PrimalEarth/Dinos/Leech/Leech_Character_Diseased.Leech_Character_Diseased": "Leech",
    "/Game/PrimalEarth/Dinos/Leedsichthys/Leedsichthys_Character_BP.Leedsichthys_Character_BP": "Leedsichthys",
    "/Game/PrimalEarth/Dinos/Liopleurodon/Liopleurodon_Character_BP.Liopleurodon_Character_BP": "Liopleurodon",
    "/Game/ScorchedEarth/Dinos/Moth/Moth_Character_BP.Moth_Character_BP": "Lymantria",
    "/Game/PrimalEarth/Dinos/Lystrosaurus/Lystro_Character_BP.Lystro_Character_BP": "Lystrosaurus",
    "/Game/PrimalEarth/Dinos/Lystrosaurus/Lystro_Character_BP_Aberrant.Lystro_Character_BP_Aberrant": "Lystrosaurus",
    "/Game/Genesis2/Dinos/Macrophage/Macrophage_Swarm_Character.Macrophage_Swarm_Character": "Macrophage",
    "/Game/Genesis2/Dinos/MilkGlider/MilkGlider_Character_BP.MilkGlider_Character_BP": "Maewing",
    "/Game/Genesis/Dinos/Cherufe/Cherufe_Character_BP.Cherufe_Character_BP": "Magmasaur",
    "/Game/PrimalEarth/Dinos/Mammoth/Mammoth_Character_BP.Mammoth_Character_BP": "Mammoth",
    "/Game/Extinction/Dinos/IceJumper/IceJumper_Character_BP.IceJumper_Character_BP": "Managarmr",
    "/Game/PrimalEarth/Dinos/Manta/Manta_Character_BP.Manta_Character_BP": "Manta",
    "/Game/PrimalEarth/Dinos/Manta/Manta_Character_BP_Aberrant.Manta_Character_BP_Aberrant": "Manta",
    "/Game/ScorchedEarth/Dinos/Manticore/Manticore_Character_BP.Manticore_Character_BP": "Manticore",
    "/Game/ScorchedEarth/Dinos/Mantis/Mantis_Character_BP.Mantis_Character_BP": "Mantis",
    "/Game/Genesis/Dinos/GiantTurtle/GiantTurtle_Character_BP.GiantTurtle_Character_BP": "Megachelon",
    "/Game/PrimalEarth/Dinos/Megalania/Megalania_Character_BP.Megalania_Character_BP": "Megalania",
    "/Game/PrimalEarth/Dinos/Megalania/Megalania_Character_BP_Aberrant.Megalania_Character_BP_Aberrant": "Megalania",
    "/Game/PrimalEarth/Dinos/Stag/Stag_Character_BP.Stag_Character_BP": "Megaloceros",
    "/Game/PrimalEarth/Dinos/Megalodon/Megalodon_Character_BP.Megalodon_Character_BP": "Megalodon",
    "/Game/Genesis/Dinos/BiomeVariants/Ocean_Megalodon/Ocean_Megalodon_Character_BP.Ocean_Megalodon_Character_BP": "Megalodon",
    "/Game/PrimalEarth/Dinos/Megalosaurus/Megalosaurus_Character_BP.Megalosaurus_Character_BP": "Megalosaurus",
    "/Game/PrimalEarth/Dinos/Megalosaurus/Megalosaurus_Character_BP_Aberrant.Megalosaurus_Character_BP_Aberrant": "Megalosaurus",
    "/Game/PrimalEarth/Dinos/Dragonfly/Dragonfly_Character_BP.Dragonfly_Character_BP": "Meganeura",
    "/Game/PrimalEarth/Dinos/Dragonfly/Dragonfly_Character_BP_Aberrant.Dragonfly_Character_BP_Aberrant": "Meganeura",
    "/Game/PrimalEarth/Dinos/Gorilla/Gorilla_Character_BP.Gorilla_Character_BP": "Megapithecus",
    "/Game/PrimalEarth/Dinos/Megatherium/Megatherium_Character_BP.Megatherium_Character_BP": "Megatherium",
    "/Game/Genesis2/Dinos/BiomeVariants/Megatherium_Character_BP_Eden.Megatherium_Character_BP_Eden": "Megatherium",
    "/Game/PrimalEarth/Dinos/Monkey/Monkey_Character_BP.Monkey_Character_BP": "Mesopithecus",
    "/Game/PrimalEarth/Dinos/Microraptor/Microraptor_Character_BP.Microraptor_Character_BP": "Microraptor",
    "/Game/ScorchedEarth/Dinos/Camelsaurus/camelsaurus_Character_BP.camelsaurus_Character_BP": "Morellatops",
    "/Game/PrimalEarth/Dinos/Mosasaurus/Mosa_Character_BP.Mosa_Character_BP": "Mosasaurus",
    "/Game/Genesis/Dinos/BiomeVariants/Ocean_Mosasaurus/Ocean_Mosa_Character_BP.Ocean_Mosa_Character_BP": "Mosasaurus",
    "/Game/PrimalEarth/Dinos/Moschops/Moschops_Character_BP.Moschops_Character_BP": "Moschops",
    "/Game/PrimalEarth/Dinos/Moschops/Moschops_Character_BP_Aberrant.Moschops_Character_BP_Aberrant": "Moschops",
    "/Game/Aberration/Dinos/ChupaCabra/ChupaCabra_Character_BP.ChupaCabra_Character_BP": "Nameless",
    "/Game/Genesis2/Dinos/BrainSlug/BrainSlug_Character_BP.BrainSlug_Character_BP": "Noglin",
    "/Game/PrimalEarth/Dinos/Bat/Bat_Character_BP.Bat_Character_BP": "Onyc",
    "/Game/PrimalEarth/Dinos/Otter/Otter_Character_BP.Otter_Character_BP": "Otter",
    "/Game/PrimalEarth/Dinos/Otter/Otter_Character_BP_Aberrant.Otter_Character_BP_Aberrant": "Otter",
    "/Game/Genesis/Dinos/BiomeVariants/Snow_Otter/Snow_Otter_Character_BP.Snow_Otter_Character_BP": "Otter",
    "/Game/EndGame/Dinos/Endboss/EndBoss_Character.EndBoss_Character": "Overseer",
    "/Game/EndGame/Dinos/Endboss/EndbossSpider/EndBossSpiderL_Character_BP.EndBossSpiderL_Character_BP": "Overseer",
    "/Game/EndGame/Dinos/Endboss/EndbossDragon/EndBossDragon_Character_BP.EndBossDragon_Character_BP": "Overseer",
    "/Game/EndGame/Dinos/Endboss/EndbossGorilla/EndBossGorilla_Character_BP.EndBossGorilla_Character_BP": "Overseer",
    "/Game/PrimalEarth/Dinos/Oviraptor/Oviraptor_Character_BP.Oviraptor_Character_BP": "Oviraptor",
    "/Game/PrimalEarth/Dinos/Sheep/Sheep_Character_BP.Sheep_Character_BP": "Ovis",
    "/Game/PrimalEarth/Dinos/Sheep/Sheep_Character_BP_Aberrant.Sheep_Character_BP_Aberrant": "Ovis",
    "/Game/PrimalEarth/Dinos/Pachy/Pachy_Character_BP.Pachy_Character_BP": "Pachy",
    "/Game/Genesis2/Missions/ModularMission/Mashup/ExperimentG/DinosAndStructures/Pachy_Character_BP_ExpG.Pachy_Character_BP_ExpG": "Pachy",
    "/Game/PrimalEarth/Dinos/Pachyrhinosaurus/Pachyrhino_Character_BP.Pachyrhino_Character_BP": "Pachyrhinosaurus",
    "/Game/PrimalEarth/Dinos/Paraceratherium/Paracer_Character_BP.Paracer_Character_BP": "Paraceratherium",
    "/Game/PrimalEarth/Dinos/Paraceratherium/Paracer_Character_BP_Aberrant.Paracer_Character_BP_Aberrant": "Paraceratherium",
    "/Game/Extinction/Dinos/Corrupt/Paraceratherium/Paracer_Character_BP_Corrupt.Paracer_Character_BP_Corrupt": "Paraceratherium",
    "/Game/Genesis/Dinos/BiomeVariants/BogParaceratherium/Bog_Paracer_Character_BP.Bog_Paracer_Character_BP": "Paraceratherium",
    "/Game/Genesis/Dinos/Swarms/MicrobeSwarmChar_BP.MicrobeSwarmChar_BP": "Parakeet Fish School",
    "/Game/PrimalEarth/Dinos/Para/Para_Character_BP.Para_Character_BP": "Parasaur",
    "/Game/PrimalEarth/Dinos/Para/BionicPara_Character_BP.BionicPara_Character_BP": "Parasaur",
    "/Game/PrimalEarth/Dinos/Para/Para_Character_BP_Aberrant.Para_Character_BP_Aberrant": "Parasaur",
    "/Game/Genesis/Dinos/BiomeVariants/BogPara/Bog_Para_Character_BP.Bog_Para_Character_BP": "Parasaur",
    "/Game/PrimalEarth/Dinos/Para/BionicPara_Character_BP_Malfunctioned.BionicPara_Character_BP_Malfunctioned": "Parasaur",
    "/Game/Genesis2/Dinos/BiomeVariants/Para_Character_BP_Eden.Para_Character_BP_Eden": "Parasaur",
    "/Game/PrimalEarth/Dinos/Pegomastax/Pegomastax_Character_BP.Pegomastax_Character_BP": "Pegomastax",
    "/Game/PrimalEarth/Dinos/Pelagornis/Pela_Character_BP.Pela_Character_BP": "Pelagornis",
    "/Game/PrimalEarth/Dinos/Phiomia/Phiomia_Character_BP.Phiomia_Character_BP": "Phiomia",
    "/Game/ScorchedEarth/Dinos/Phoenix/Phoenix_Character_BP.Phoenix_Character_BP": "Phoenix",
    "/Game/PrimalEarth/Dinos/Piranha/Piranha_Character_BP.Piranha_Character_BP": "Piranha",
    "/Game/PrimalEarth/Dinos/Piranha/Piranha_Character_BP_Aberrant.Piranha_Character_BP_Aberrant": "Piranha",
    "/Game/PrimalEarth/Dinos/Plesiosaur/Plesiosaur_Character_BP.Plesiosaur_Character_BP": "Plesiosaur",
    "/Game/PrimalEarth/Dinos/Procoptodon/Procoptodon_Character_BP.Procoptodon_Character_BP": "Procoptodon",
    "/Game/Genesis2/Dinos/BiomeVariants/Procoptodon_Character_BP_Eden.Procoptodon_Character_BP_Eden": "Procoptodon",
    "/Game/PrimalEarth/Dinos/Ptero/Ptero_Character_BP.Ptero_Character_BP": "Pteranodon",
    "/Game/Extinction/Dinos/Corrupt/Ptero/Ptero_Character_BP_Corrupt.Ptero_Character_BP_Corrupt": "Pteranodon",
    "/Game/PrimalEarth/Dinos/Scorpion/Scorpion_Character_BP.Scorpion_Character_BP": "Pulmonoscorpius",
    "/Game/PrimalEarth/Dinos/Scorpion/Scorpion_Character_BP_Aberrant.Scorpion_Character_BP_Aberrant": "Pulmonoscorpius",
    "/Game/PrimalEarth/Dinos/Purlovia/Purlovia_Character_BP.Purlovia_Character_BP": "Purlovia",
    "/Game/PrimalEarth/Dinos/Purlovia/Purlovia_Character_BP_Polar.Purlovia_Character_BP_Polar": "Purlovia",
    "/Game/PrimalEarth/Dinos/Purlovia/Purlovia_Character_BP_Aberrant.Purlovia_Character_BP_Aberrant": "Purlovia",
    "/Game/PrimalEarth/Dinos/Quetzalcoatlus/Quetz_Character_BP.Quetz_Character_BP": "Quetzal",
    "/Game/PrimalEarth/Dinos/Quetzalcoatlus/BionicQuetz_Character_BP.BionicQuetz_Character_BP": "Quetzal",
    "/Game/PrimalEarth/Dinos/Quetzalcoatlus/BionicQuetz_Character_BP_Malfunctioned.BionicQuetz_Character_BP_Malfunctioned": "Quetzal",
    "/Game/Genesis2/Dinos/BiomeVariants/Quetz_Character_BP_Rockwell.Quetz_Character_BP_Rockwell": "Quetzal",
    "/Game/PrimalEarth/Dinos/Raptor/Raptor_Character_BP.Raptor_Character_BP": "Raptor",
    "/Game/PrimalEarth/Dinos/Raptor/BionicRaptor_Character_BP.BionicRaptor_Character_BP": "Raptor",
    "/Game/PrimalEarth/Dinos/Raptor/Raptor_Character_BP_Aberrant.Raptor_Character_BP_Aberrant": "Raptor",
    "/Game/Extinction/Dinos/Corrupt/Raptor/Raptor_Character_BP_Corrupt.Raptor_Character_BP_Corrupt": "Raptor",
    "/Game/Genesis/Dinos/BiomeVariants/Bog_Raptor/Bog_Raptor_Character_BP.Bog_Raptor_Character_BP": "Raptor",
    "/Game/PrimalEarth/Dinos/Raptor/BionicRaptor_Character_BP_Malfunctioned.BionicRaptor_Character_BP_Malfunctioned": "Raptor",
    "/Game/Genesis/Dinos/BiomeVariants/VR/VRRaptor_Character_BP.VRRaptor_Character_BP": "Raptor",
    "/Game/Aberration/Dinos/CaveWolf/CaveWolf_Character_BP.CaveWolf_Character_BP": "Ravager",
    "/Game/Aberration/Dinos/Nameless/Xenomorph_Character_BP_Male_Tamed.Xenomorph_Character_BP_Male_Tamed": "Reaper",
    "/Game/Aberration/Dinos/Nameless/Xenomorph_Character_BP_Male_Minion.Xenomorph_Character_BP_Male_Minion": "Reaper",
    "/Game/Aberration/Dinos/Nameless/Xenomorph_Character_BP_Male_Surface.Xenomorph_Character_BP_Male_Surface": "Reaper",
    "/Game/Aberration/Dinos/Nameless/Xenomorph_Character_BP_Female.Xenomorph_Character_BP_Female": "Reaper",
    "/Game/Extinction/Dinos/Corrupt/Nameless/Xenomorph_Character_BP_Male_Tamed_Corrupt.Xenomorph_Character_BP_Male_Tamed_Corrupt": "Reaper",
    "/Game/Aberration/Dinos/Nameless/Xenomorph_Character_BP_Male_Lunar.Xenomorph_Character_BP_Male_Lunar": "Reaper",
    "/Game/Genesis/Dinos/MissionVariants/Escort/Lunar/Xenomorph_Character_BP_Male_InitialBuryOnly_Adolescent_Escort.Xenomorph_Character_BP_Male_InitialBuryOnly_Adolescent_Escort": "Reaper",
    "/Game/Genesis2/Dinos/BiomeVariants/Xenomorph_Character_BP_Female_Gen2.Xenomorph_Character_BP_Female_Gen2": "Reaper",
    "/Game/Genesis2/Dinos/BiomeVariants/Xenomorph_Character_BP_Male_Tamed_Gen2.Xenomorph_Character_BP_Male_Tamed_Gen2": "Reaper",
    "/Game/PrimalEarth/Dinos/Rex/Rex_Character_BP.Rex_Character_BP": "Rex",
    "/Game/PrimalEarth/Dinos/Rex/BionicRex_Character_BP.BionicRex_Character_BP": "Rex",
    "/Game/Extinction/Dinos/Corrupt/Rex/Rex_Character_BP_Corrupt.Rex_Character_BP_Corrupt": "Rex",
    "/Game/Genesis/Dinos/BiomeVariants/Volcano_Rex/Volcano_Rex_Character_BP.Volcano_Rex_Character_BP": "Rex",
    "/Game/PrimalEarth/Dinos/Rex/BionicRex_Character_BP_Malfunctioned.BionicRex_Character_BP_Malfunctioned": "Rex",
    "/Game/Genesis/Dinos/BiomeVariants/VR/VRRex_Character_BP.VRRex_Character_BP": "Rex",
    "/Game/Aberration/Dinos/RockDrake/RockDrake_Character_BP.RockDrake_Character_BP": "Rock Drake",
    "/Game/Extinction/Dinos/Corrupt/RockDrake/RockDrake_Character_BP_Corrupt.RockDrake_Character_BP_Corrupt": "Rock Drake",
    "/Game/Aberration/Boss/Rockwell/Rockwell_Character_BP.Rockwell_Character_BP": "Rockwell",
    "/Game/Aberration/Boss/Rockwell/Rockwell_Character_BP_Easy.Rockwell_Character_BP_Easy": "Rockwell",
    "/Game/Aberration/Boss/Rockwell/Rockwell_Character_BP_Medium.Rockwell_Character_BP_Medium": "Rockwell",
    "/Game/Aberration/Boss/Rockwell/Rockwell_Character_BP_Hard.Rockwell_Character_BP_Hard": "Rockwell",
    "/Game/Aberration/Boss/RockwellTentacle/RockwellTentacle_Character_BP.RockwellTentacle_Character_BP": "Rockwell",
    "/Game//Game/Genesis2/Missions/ModularMission/FinalBattleAlt/Dinos/RockwellNode_Character_BP_FinalFight.RockwellNode_Character_BP_FinalFight": "Rockwell Node",
    "/Game/Genesis2/Missions/ModularMission/FinalBattleAlt/Difficulties/Gamma/RockwellNode_Character_BP_Boss_Gamma.RockwellNode_Character_BP_Boss_Gamma": "Rockwell Prime",
    "/Game/Aberration/Dinos/MoleRat/MoleRat_Character_BP.MoleRat_Character_BP": "Roll Rat",
    "/Game/PrimalEarth/Dinos/Saber/Saber_Character_BP.Saber_Character_BP": "Sabertooth",
    "/Game/Genesis/Dinos/BiomeVariants/Snow_Saber/Snow_Saber_Character_BP.Snow_Saber_Character_BP": "Sabertooth",
    "/Game/PrimalEarth/Dinos/Salmon/Salmon_Character_BP.Salmon_Character_BP": "Sabertooth Salmon",
    "/Game/PrimalEarth/Dinos/Salmon/Salmon_Character_Aberrant.Salmon_Character_Aberrant": "Sabertooth Salmon",
    "/Game/Genesis/Dinos/BiomeVariants/Lunar_Salmon/Lunar_Salmon_Character_BP.Lunar_Salmon_Character_BP": "Sabertooth Salmon",
    "/Game/Genesis/Dinos/BiomeVariants/Lunar_Salmon/Rare_Lunar_Salmon_Character_BP.Rare_Lunar_Salmon_Character_BP": "Sabertooth Salmon",
    "/Game/PrimalEarth/Dinos/Sarco/Sarco_Character_BP.Sarco_Character_BP": "Sarco",
    "/Game/PrimalEarth/Dinos/Sarco/Sarco_Character_BP_Aberrant.Sarco_Character_BP_Aberrant": "Sarco",
    "/Game/Extinction/Dinos/Scout/Scout_Character_BP.Scout_Character_BP": "Scout",
    "/Game/Aberration/Dinos/Pteroteuthis/Pteroteuthis_Char_BP.Pteroteuthis_Char_BP": "Seeker",
    "/Game/Genesis2/Dinos/LionfishLion/LionfishLion_Character_BP.LionfishLion_Character_BP": "Shadowmane",
    "/Game/Aberration/Dinos/LanternGoat/LanternGoat_Character_BP.LanternGoat_Character_BP": "Shinehorn",
    "/Game/LostIsland/Dinos/Sinomacrops/Sinomacrops_Character_BP.Sinomacrops_Character_BP": "Sinomacrops",
    "/Game/Extinction/Dinos/Owl/Owl_Character_BP.Owl_Character_BP": "Snow Owl",
    "/Game/Genesis2/Dinos/BiomeVariants/Owl_Character_BP_Eden.Owl_Character_BP_Eden": "Snow Owl",
    "/Game/PrimalEarth/Dinos/Spino/Spino_Character_BP.Spino_Character_BP": "Spino",
    "/Game/PrimalEarth/Dinos/Spino/Spino_Character_BP_Aberrant.Spino_Character_BP_Aberrant": "Spino",
    "/Game/Extinction/Dinos/Corrupt/Spino/Spino_Character_BP_Corrupt.Spino_Character_BP_Corrupt": "Spino",
    "/Game/Genesis/Dinos/BiomeVariants/Bog_Spino/Bog_Spino_Character_BP.Bog_Spino_Character_BP": "Spino",
    "/Game/PrimalEarth/Dinos/Stego/Stego_Character_BP.Stego_Character_BP": "Stegosaurus",
    "/Game/PrimalEarth/Dinos/Stego/BionicStego_Character_BP.BionicStego_Character_BP": "Stegosaurus",
    "/Game/PrimalEarth/Dinos/Stego/Stego_Character_BP_Aberrant.Stego_Character_BP_Aberrant": "Stegosaurus",
    "/Game/Extinction/Dinos/Corrupt/Stego/Stego_Character_BP_Corrupt.Stego_Character_BP_Corrupt": "Stegosaurus",
    "/Game/PrimalEarth/Dinos/Stego/BionicStego_Character_BP_Malfunctioned.BionicStego_Character_BP_Malfunctioned": "Stegosaurus",
    "/Game/Genesis2/Dinos/Summoner/Summoner_Character_BP.Summoner_Character_BP": "Summoner",
    "/Game/Genesis2/Missions/ModularMission/Maze/CorruptedZone/Dinos/Summoner_Character_BP_CorruptedMaze.Summoner_Character_BP_CorruptedMaze": "Summoner",
    "/Game/PrimalEarth/Dinos/Tapejara/Tapejara_Character_BP.Tapejara_Character_BP": "Tapejara",
    "/Game/Genesis/Dinos/BiomeVariants/Bog_Tapejara/Bog_Tapejara_Character_BP.Bog_Tapejara_Character_BP": "Tapejara",
    "/Game/Genesis2/Dinos/TekStrider/TekStrider_Character_BP.TekStrider_Character_BP": "Tek Stryder",
    "/Game/PrimalEarth/Dinos/TerrorBird/TerrorBird_Character_BP.TerrorBird_Character_BP": "Terror Bird",
    "/Game/PrimalEarth/Dinos/Therizinosaurus/Therizino_Character_BP.Therizino_Character_BP": "Therizinosaur",
    "/Game/ScorchedEarth/Dinos/SpineyLizard/SpineyLizard_Character_BP.SpineyLizard_Character_BP": "Thorny Dragon",
    "/Game/PrimalEarth/Dinos/Thylacoleo/Thylacoleo_Character_BP.Thylacoleo_Character_BP": "Thylacoleo",
    "/Game/Genesis2/Dinos/BiomeVariants/Thylacoleo_Character_BP_Eden.Thylacoleo_Character_BP_Eden": "Thylacoleo",
    "/Game/PrimalEarth/Dinos/BoaFrill/BoaFrill_Character_BP.BoaFrill_Character_BP": "Titanoboa",
    "/Game/PrimalEarth/Dinos/BoaFrill/BoaFrill_Character_BP_Aberrant.BoaFrill_Character_BP_Aberrant": "Titanoboa",
    "/Game/PrimalEarth/Dinos/Ant/Ant_Character_BP.Ant_Character_BP": "Titanomyrma",
    "/Game/PrimalEarth/Dinos/Ant/FlyingAnt_Character_BP.FlyingAnt_Character_BP": "Titanomyrma",
    "/Game/PrimalEarth/Dinos/Titanosaur/Titanosaur_Character_BP.Titanosaur_Character_BP": "Titanosaur",
    "/Game/PrimalEarth/Dinos/Trike/Trike_Character_BP.Trike_Character_BP": "Triceratops",
    "/Game/PrimalEarth/Dinos/Trike/Trike_Character_BP_Aberrant.Trike_Character_BP_Aberrant": "Triceratops",
    "/Game/Extinction/Dinos/Corrupt/Trike/Trike_Character_BP_Corrupt.Trike_Character_BP_Corrupt": "Triceratops",
    "/Game/PrimalEarth/Dinos/Trike/BionicTrike_Character_BP.BionicTrike_Character_BP": "Triceratops",
    "/Game/Genesis/Dinos/BiomeVariants/Volcano_Trike/Volcano_Trike_Character_BP.Volcano_Trike_Character_BP": "Triceratops",
    "/Game/PrimalEarth/Dinos/Trike/BionicTrike_Character_BP_Malfunctioned.BionicTrike_Character_BP_Malfunctioned": "Triceratops",
    "/Game/Genesis/Dinos/BiomeVariants/VR/VRTrike_Character_BP.VRTrike_Character_BP": "Triceratops",
    "/Game/PrimalEarth/Dinos/Trilobite/Trilobite_Character.Trilobite_Character": "Trilobite",
    "/Game/PrimalEarth/Dinos/Trilobite/Trilobite_Character_Aberrant.Trilobite_Character_Aberrant": "Trilobite",
    "/Game/PrimalEarth/Dinos/Troodon/Troodon_Character_BP.Troodon_Character_BP": "Troodon",
    "/Game/PrimalEarth/Dinos/Tropeognathus/Tropeognathus_Character_BP.Tropeognathus_Character_BP": "Tropeognathus",
    "/Game/PrimalEarth/Dinos/Tusoteuthis/Tusoteuthis_Character_BP.Tusoteuthis_Character_BP": "Tusoteuthis",
    "/Game/Extinction/Dinos/Spindles/Spindles_Character_BP.Spindles_Character_BP": "Velonasaur",
    "/Game/Genesis2/Dinos/BiomeVariants/Spindles_Character_BP_Rockwell.Spindles_Character_BP_Rockwell": "Velonasaur",
    "/Game/ScorchedEarth/Dinos/Vulture/Vulture_Character_BP.Vulture_Character_BP": "Vulture",
    "/Game/PrimalEarth/Dinos/WoollyRhino/Rhino_Character_BP.Rhino_Character_BP": "Woolly Rhino",
    "/Game/Genesis/Dinos/BiomeVariants/Snow_WoollyRhino/Snow_Rhino_Character_BP.Snow_Rhino_Character_BP": "Woolly Rhino",
    "/Game/PrimalEarth/Dinos/Yutyrannus/Yutyrannus_Character_BP.Yutyrannus_Character_BP": "Yutyrannus",
    "/Game/Genesis/Dinos/BiomeVariants/Snow_Yutyrannus/Snow_Yutyrannus_Character_BP.Snow_Yutyrannus_Character_BP": "Yutyrannus"
}