import React, { useState, useEffect } from 'react'
import {
    Box, 
    Fade,
    Grid,
    Tab,
    Tabs,
    TextField,
    Zoom
} from '@mui/material'
import ItemCard from './ItemCard/ItemCard'
import DinoCard from './ItemCard/DinoCard'
import { TransitionGroup } from 'react-transition-group'

// TODO: Bundle this with the two Card Classes for easier moving around

function AuctionBrowser() {
    const [data, setData] = useState({})
    const [tabIndex, setTabIndex] = React.useState(Number(localStorage.getItem('tabIndex')))
    const [filter, setFilter] = React.useState('')

     useEffect(() => {
        const fetchData = async () => {
            const data = await fetch('https://linode.ghazlawl.com/ark/mods/auctionhouse/api/json/v1/auctions/?MarketID=tekedge200')
            const json = await data.json()
            setData(json)
        }

        fetchData().catch(console.error)
    }, [])

    const filterAuctions = (auction) => {
        switch(tabIndex) {
            case 0:
                if (auction.Type === 'Dino') return false
                break
            case 1:
                if (auction.Type !== 'Dino') return false
                break
            default:
        }
        if (filter.length > 1) {
            const searchTerms = `${auction.Name.toLowerCase()} ${auction.Seller.Name.toLowerCase()} ${auction.Item && auction.Item.Flags.IsBluePrint ? 'blueprint' : ''}`
            return searchTerms.indexOf(filter.toLowerCase()) > -1
        }
        return true
    }

    const handleFilter = (value) => {
        console.log(JSON.stringify(data.Auctions, null, 2))
        setFilter(value)        
    }

    const handleTabIndex = (_, index) => {
        localStorage.setItem('tabIndex', index)
        setTabIndex(index)
    }

    return (
        <div>
            <TextField label="Search" variant="filled" sx={{width: '100%', mb: 3, borderRadius: 10 }} value={filter} onChange={(e) => handleFilter(e.target.value)} />
            <Box sx={{ border: 1, borderColor: 'divider', borderRadius: 3, minHeight: 48 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                    <Tabs value={tabIndex} onChange={handleTabIndex} aria-label="basic tabs example">
                        <Tab label='Items' value={0} />
                        <Tab label='Dinos' value={1} />
                    </Tabs>
                </Box>
                <TransitionGroup component={Grid} container spacing={4}>
                    { data.Auctions ? data.Auctions.filter(filterAuctions).map((auction, index) =>  (
                            <Fade key={`${auction.Date} ${index}`}>
                                <Grid  item xs={12} md={6} lg={4} xl={3}>
                                    {auction.Type === 'Dino' ? (<DinoCard auction={auction} />) : (<ItemCard auction={auction} />) }               
                                </Grid>
                            </Fade  >
                        )
                    ) : null }
                </TransitionGroup>
            </Box>
        </div>
    )
}

export default AuctionBrowser