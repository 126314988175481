import React from 'react'
import { 
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Typography
} from '@mui/material'
import './ItemCard.css'
import { CircleTwoTone} from '@mui/icons-material'
import itemMap from './item-map'


function ItemCard({ auction }) {
    function itemQuality(value) {
        const qualities = ['Primitive', 'Ramshackle', 'Apprentice', 'Journeyman', 'Mastercraft', 'Ascendant']
        return value.Item && value.Item.Quality >= 0 ? qualities[value.Item.Quality] : null
    }

    // TODO: Move this into a common utility class
    function currency(item) {
        const match = item.AskingClass.match(/Coin_(?<currency>.*)_C/)
        if (match) return match.groups.currency
    }

    function itemStats(item) {
        let stats = []
        if (item && item.Stats) {
            if (item.Stats.Armor > 0) {
                stats.push(['Armor', item.Stats.Armor])
            } else {
                stats.push(['Damage', `${item.Stats.Damage}%`])
            }
            if (item.Stats.CraftedSkillBonus > 0) {
                stats.push(['Crafting Bonus', `${(item.Stats.CraftedSkillBonus * 100).toFixed(2)}%`])
            }
            if (item.Stats.MaxDurability > 0 && (item.Stats.Damage > 0 || item.Stats.HyperthermalInsulation > 0 || item.Stats.HypothermalInsulation > 0)) {
                stats.push(['Durability', item.Stats.MaxDurability])
            }
        }
        return stats
    }

    function itemName(auction) {
        let path = auction.SellingClasspath
        if (auction.Type === 'Egg') {
            if (path.indexOf('Wyvern') > -1) return 'Wyvern Egg'
            path = path.replaceAll('_Fertilized', '')
        }
        if (itemMap[path]) return itemMap[path]
        if (auction.Type === 'Egg') {            
            return 'Dodo Egg'
        } else {
            return 'Lootcrate'
        }
    }

    return (
        // TODO: Break these two out into their own class
        <Card sx={{ margin: 'auto', maxWidth: 450, minHeight: 500, display: 'inline-grid', gridTemplateRows: 'min-content auto min-content', width: '100%' }}>
            <div className={itemQuality(auction)} style={{ height: 240, display: 'flex', alignItems: 'center', boxShadow: 'inset 0 0 110px #000000' }}>
                <CardMedia
                    component="img"
                    height="180"
                    image={ `http://d2harz19bqzzx5.cloudfront.net/images/items/${itemName(auction)}.png` || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=' }
                    onError={(e) => e.target.src = `http://d2harz19bqzzx5.cloudfront.net/images/items/Lootcrate.png` || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='  }
                    alt={ auction.Name }
                    sx={{ objectFit: 'contain' }}
                />
            </div>

            <CardContent>
                <Typography variant="h5">
                    { `${auction.Name}${auction.Quantity > 1 ? ` - ${auction.Quantity}` : ''}`}
                </Typography>
                <Typography variant="subtitle1">
                    { auction.Item && auction.Item.Flags.IsBlueprint ? 'Blueprint' : '-' }
                </Typography>
                <div>
                    <div style={{ minHeight: 96 }}>
                        { itemStats(auction.Item).map((value, index) => (
                            <div className='stat-line' key={index}>
                                <span>{ value[0] }</span>
                                <span>{ value[1] }</span>
                            </div>
                        ))}
                    </div>
                    <div className='stat-line'>
                        <span>Sold By</span>
                        <span>{ auction.Seller.Name }</span>
                    </div> 
                </div>
            </CardContent>
            <CardActions className="price">
                <Typography variant="h6">                    
                    { auction.AskingAmount } <img style={{ marginBottom: -8, height: 32 }} alt="Tek Shard" src="TekShard_96.png"/>
                </Typography>
            </CardActions>
        </Card>
    )
}

export default ItemCard
