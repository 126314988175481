import React from 'react'
import { 
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Typography
} from '@mui/material'
import './ItemCard.css'
import { CircleTwoTone} from '@mui/icons-material'
import dinoMap from './dino-map'

// TODO: Rename folder that this is in, doesn't fit to have one component using it's name and another not

function DinoCard({ auction }) {
    // TODO: Move into common utility
    function currency(item) {
        const match = item.AskingClass.match(/Coin_(?<currency>.*)_C/)
        if (match) return match.groups.currency
    }

    function dinoLevel(value) {
        return `Level ${value.Dino.Level} - ${value.Dino.Gender}`
    }

    function dinoStats(dino) {
        let stats = []
        for (let key of Object.keys(dino.Points.Wild)) {
            if (dino.Points.Wild[key.toString()] > 0 || key === 'Speed') {
                stats.push([key, `${dino.Points.Wild[key]}${dino.Points.Tamed[key] > 0 ? `(+${dino.Points.Tamed[key]})`: '' }` ])
            }
        }
        return stats
    }

    function itemName(auction) {
        const path = auction.SellingClasspath
        if (dinoMap[path]) {
            return dinoMap[path]
        } else {
            return 'Unknown'
        }
    }

    return (
        // TODO: Break this into it's own class
        <Card sx={{ margin: 'auto', maxWidth: 450, minHeight: 500, display: 'inline-grid', gridTemplateRows: 'min-content auto min-content', width: '100%' }}>
            <div className={ auction.Dino.Gender } style={{ height: 240, display: 'flex', alignItems: 'center', boxShadow: 'inset 0 0 110px #000000' }}>
                <CardMedia
                    component="img"
                    height="180"
                    image={ `http://d2harz19bqzzx5.cloudfront.net/images/dinos/${itemName(auction)}.png` || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=' }
                    onError={(e) => e.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=' || `http://d2harz19bqzzx5.cloudfront.net/images/dinos/Unknown.png` }
                    alt="RiotPants"
                    sx={{ objectFit: 'contain', }}
                />
            </div>
            <CardContent>
                <Typography variant="h5">
                    { auction.Name }
                </Typography>
                <Typography variant="subtitle1">
                    { auction.Dino && auction.Dino.Flags.IsNeutered ? 'Neutered' : '-' }
                </Typography>
                <Typography variant="subtitle1">
                    { dinoLevel(auction) }
                </Typography>
                <div>
                    <div style={{ minHeight: 96, display: 'grid', gridTemplateColumns: 'auto auto', gridColumnGap: 40, padding: '16px 0' }}>
                        { dinoStats(auction.Dino).map((value, index) => (
                            <div className='stat-line' key={index}>
                                <span>{ value[0] }</span>
                                <span>{ value[1] }</span>
                            </div>
                        ))}
                    </div>
                    <div className='stat-line'>
                        <span>Sold By</span>
                        <span>{ auction.Seller.Name }</span>
                    </div> 
                </div>
            </CardContent>
            <CardActions className="price">
                <Typography variant="h6">
                    { auction.AskingAmount } <img style={{ marginBottom: -8, height: 32 }} alt="Tek Shard" src="TekShard_96.png"/>
                </Typography>
            </CardActions>
        </Card>
    )
}

export default DinoCard
